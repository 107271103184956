import { useMemo } from 'react';
import { GetStaticPropsContext } from 'next';
import { getNextStaticProps, is404 } from '@faustjs/next';

import Blocks from '@/blocks/Blocks/Blocks';
import Head from '@/components/Head/Head';

import { client, Page as PageType, PageIdType } from '@/client';

export interface PageProps {
  page: PageType | null | undefined;
}

export function Home({ page }: PageProps) {
  const { title, fullHead } = page?.seo ?? {};
  const blocksJSON = page?.blocksJSON;

  const blocks = useMemo(() => {
    return blocksJSON ? JSON.parse(blocksJSON) : [];
  }, [blocksJSON]);

  return (
    <main>
      <Head title={title} fullHead={fullHead} />

      <Blocks blocks={blocks} />
    </main>
  );
}

export default function Page() {
  const { usePage } = client;
  const page = usePage({
    id: '/',
    idType: PageIdType.URI
  });

  return <Home page={page} />;
}

export async function getStaticProps(context: GetStaticPropsContext) {
  return getNextStaticProps(context, {
    Page,
    client,
    notFound: await is404(context, { client })
  });
}
